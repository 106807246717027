import React, { Fragment, useEffect, useState } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useDebouncedCallback } from 'use-debounce';
import { useImmer } from 'use-immer';
import data from './criteria.yaml';
import wordmark from './wordmark.svg';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4bbfa9',
    },
    secondary: {
      main: '#ff5c5c',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
});

const allRoles = new Set();
data.criteria.forEach((criterion) =>
  criterion.tags.forEach((tag) => allRoles.add(tag)),
);

console.log(data);

const AssessmentTabs = ({
  currentAssessmentId,
  onChange,
  assessments,
  updateTitle,
}) => (
  <Tabs
    value={currentAssessmentId}
    indicatorColor="primary"
    textColor="primary"
    variant="scrollable"
    onChange={onChange}
  >
    <Tab label={<AddIcon />} value={-1} />
    {assessments.map((assessment, index) => (
      <Tab
        label={
          <Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              {assessment.title}
              {index === currentAssessmentId && (
                <Box position="absolute" right={4}>
                  <EditIcon
                    fontSize="small"
                    onClick={() => {
                      const title = prompt('Enter title', assessment.title);
                      if (title !== null) {
                        updateTitle(title);
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box>
              <Typography variant="body2" color="textSecondary">
                {assessment.role}
              </Typography>
            </Box>
          </Box>
        }
        value={index}
      />
    ))}
  </Tabs>
);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box>
          <Typography variant="h2">Something went wrong.</Typography>
          {this.props.fallbackComponent}
        </Box>
      );
    }

    return this.props.children;
  }
}

function App() {
  const initialAssessments = JSON.parse(localStorage.getItem('data')) || [];
  const initialCurrentAssessmentId = initialAssessments.length > 0 ? 0 : -1; // -1 means New Assessment
  const [assessments, updateAssessments] = useImmer(initialAssessments);
  const [currentAssessmentId, setCurrentAssessmentId] = useState(
    initialCurrentAssessmentId,
  );
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  const addNewAssessment = (role) => {
    updateAssessments((draft) => {
      const title = new Date().toLocaleDateString(undefined, {
        month: 'short',
        year: 'numeric',
      });

      const filteredCriteria = data.criteria.filter((criterion) => {
        return criterion.tags.includes(role);
      });

      const dedupedCriteria = filteredCriteria.reduce((acc, criterion) => {
        acc[criterion.header] = acc[criterion.header] || [];
        acc[criterion.header].push(...criterion.description);
        return acc;
      }, {});

      const criteria = Object.entries(dedupedCriteria).map(
        ([header, descriptions]) => ({
          header,
          descriptions,
        }),
      );

      draft.unshift({
        version: data.version,
        role,
        title,
        criteria,
        id: Math.random(),
      });
    });
    setCurrentAssessmentId(0);
  };

  const setNote = (note, index) => {
    updateAssessments((draft) => {
      draft[currentAssessmentId].criteria[index].notes = note;
    });
  };

  const deleteCurrentAssessment = () => {
    updateAssessments((draft) => {
      draft.splice(currentAssessmentId, 1);
      if (currentAssessmentId >= draft.length) {
        setCurrentAssessmentId(draft.length - 1);
      }
      window.scrollTo(0, 0);
    });
  };

  const updateTitle = (title) => {
    updateAssessments((draft) => {
      draft[currentAssessmentId].title = title;
    });
  };

  const debounced = useDebouncedCallback(() => {
    localStorage.setItem('data', JSON.stringify(assessments));
    setHasPendingChanges(false);
  }, 1000);

  useEffect(() => {
    setHasPendingChanges(true);
    debounced.callback();
  }, [assessments, debounced]);

  useEffect(() => {
    const promptLeave = (event) => {
      if (hasPendingChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', promptLeave);

    return () => {
      window.removeEventListener('beforeunload', promptLeave);
    };
  }, [hasPendingChanges]);

  const copyToClipboard = async () => {
    const result = await navigator.permissions.query({
      name: 'clipboard-write',
    });
    if (result.state === 'granted' || result.state === 'prompt') {
      navigator.clipboard.writeText(JSON.stringify(assessments));
    }
    setIsToastOpen(true);
    setToastMessage('Copied to clipboard!');
  };

  const pasteFromClipboard = async () => {
    const text = await navigator.clipboard.readText();
    updateAssessments((draft) => {
      try {
        const newDraft = JSON.parse(text);
        setToastMessage('Pasted from clipboard!');
        setIsToastOpen(true);
        return newDraft;
      } catch {
        setToastMessage('Error pasting from clipboard!');
        setIsToastOpen(true);
      }
    });
  };

  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState();

  const handleCloseToast = () => {
    setIsToastOpen(false);
  };

  const reset = () => {
    updateAssessments((draft) => []);
    setCurrentAssessmentId(-1);
    setToastMessage('Reset!');
    setIsToastOpen(true);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const currentAssessment = assessments[currentAssessmentId];

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <ErrorBoundary
          key={assessments}
          fallbackComponent={
            <Button variant="contained" onClick={reset}>
              Reset
            </Button>
          }
        >
          <Box mt={3}>
            <img src={wordmark} alt="logo" height="32px" />
          </Box>
          <Box display="flex">
            <Box flexGrow={1} overflow="auto">
              <AssessmentTabs
                onChange={(event, newValue) => setCurrentAssessmentId(newValue)}
                currentAssessmentId={currentAssessmentId}
                assessments={assessments}
                updateTitle={updateTitle}
              />
            </Box>
            <Box display="flex">
              <Hidden xsDown>
                <IconButton onClick={pasteFromClipboard}>
                  <CloudUploadIcon />
                </IconButton>
                <IconButton onClick={copyToClipboard}>
                  <CloudDownloadIcon />
                </IconButton>
              </Hidden>
              <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                <MoreVertIcon />
              </IconButton>
            </Box>
          </Box>
          <Box py={6}>
            {currentAssessment ? (
              <Fragment>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography gutterBottom variant="h4" component="h1">
                      {currentAssessment.role}
                    </Typography>
                  </Box>
                  <Chip label={currentAssessment.version} />
                </Box>
                {currentAssessment.criteria.map(
                  ({ header, descriptions, notes }, index) => {
                    return (
                      <Box key={`${currentAssessment.id}-${header}`} mb={3}>
                        <Typography gutterBottom variant="h5" component="h2">
                          {header}
                        </Typography>
                        <Grid container spacing={2} direction="row">
                          <Grid item xs={12} sm={6}>
                            <Card variant="outlined">
                              <CardContent>
                                {descriptions.map((description) => (
                                  <Typography variantMapping={{ body1: 'div' }}>
                                    {description}
                                  </Typography>
                                ))}
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              placeholder="Your assessment"
                              multiline
                              variant="outlined"
                              fullWidth
                              value={notes}
                              onChange={(event) =>
                                setNote(event.target.value, index)
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  },
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={deleteCurrentAssessment}
                >
                  Delete
                </Button>
              </Fragment>
            ) : (
              <Grid container spacing={2} direction="row-reverse">
                <Grid container item xs={12} sm justify="flex-end">
                  <Chip label={data.version} />
                </Grid>
                <Grid container item xs={12} sm={11} spacing={2}>
                  {[...allRoles].map((role) => (
                    <Grid item xs={12} sm>
                      <Button
                        fullWidth
                        variant="contained"
                        color="default"
                        onClick={() => addNewAssessment(role)}
                      >
                        {role}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Box>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isToastOpen}
            autoHideDuration={2000}
            onClose={handleCloseToast}
            message={toastMessage}
          />
          <Menu
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            open={anchorEl !== null}
          >
            <Hidden smUp>
              <MenuItem
                onClick={() => {
                  pasteFromClipboard();
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <CloudUploadIcon />
                </ListItemIcon>
                <ListItemText primary="Upload" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  copyToClipboard();
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <CloudDownloadIcon />
                </ListItemIcon>
                <ListItemText primary="Download" />
              </MenuItem>
            </Hidden>
            <MenuItem
              onClick={() => {
                reset();
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <DeleteForeverIcon />
              </ListItemIcon>
              <ListItemText primary="Reset" />
            </MenuItem>
          </Menu>
        </ErrorBoundary>
      </Container>
    </ThemeProvider>
  );
}

export default App;
